import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import YoutubePlaylist from 'components/youtube-playlist'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import combineClinic2021Data from 'data/clinics/combine-clinic-2021'

const courseKey = 'combineSetup'

const courseData = combineClinic2021Data.find(obj => obj.key === courseKey)

const CombineSetup = props => {
  const {
    data: { heroImage },
    data,
    location,
  } = props
  const params = new URLSearchParams(location.search)
  const videoId = params.get('videoId')
  return (
    <Layout>
      <Helmet>
        <title>{courseData.title} | Combine Clinic 2021 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title={courseData.title}
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>{courseData.subtitle}</H2>
        <P>{courseData.description}</P>
      </Content>

      <YoutubePlaylist list={courseData.playlist} videoId={videoId} />

      <Content kind='full'>
        <H2 style={{ textAlign: 'center' }}>More Combine Clinic Courses</H2>
        <Spacer size='m' />
        <Grid>
          {combineClinic2021Data.map(obj => {
            if (obj.key === courseKey) {
              return null
            }
            return (
              <Column key={obj.key}>
                <GatsbyImage image={data[obj.imageKey].childImageSharp.gatsbyImageData} alt='' />
                <ColumnContent>
                  <H3>{obj.title}</H3>
                  <Spacer size='s' />
                  <Button
                    as={ButtonLink}
                    to={obj.url}
                    ghost
                    color='green'
                    aria-label={`View the ${obj.title} Course`}
                  >
                    View course
                  </Button>
                </ColumnContent>
              </Column>
            )
          })}
        </Grid>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 600px) {
    ${clearfix}
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  background-color: #fff;
  text-align: center;

  h3 {
    text-align: center;
  }

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 600px) {
    ${column('1/2')}
    display: flex;
    flex-direction: column;

    .gatsby-image-wrapper {
      flex-grow: 2;
    }
  }

  @media (min-width: 900px) {
    ${column('1/4')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "clinics/combine-clinic-2021-hero.jpg" }) {
      ...FullWidthImage
    }
    displayImage: file(relativePath: { eq: "clinics/display.jpg" }) {
      ...SharpImage360
    }
    combineImage: file(relativePath: { eq: "clinics/combine.jpg" }) {
      ...SharpImage360
    }
    combineSetupImage: file(relativePath: { eq: "clinics/combine-setup.jpg" }) {
      ...SharpImage360
    }
    n500cImage: file(relativePath: { eq: "clinics/n500c.jpg" }) {
      ...SharpImage360
    }
    operationsCenterImage: file(relativePath: { eq: "clinics/operations-center.jpg" }) {
      ...SharpImage360
    }
  }
`

export default CombineSetup
